import { Tooltip } from 'antd';
import { useRouter } from 'next/router';
import styles from './index.module.less';
import { AllSupportLanguages, CurrentLanguage } from '@/shared/language';
import { useConfigProvider } from '@/context/ConfigProvider';
import { isDrFans } from '@/shared/app-common';
// import Cookies from 'js-cookie';
// import { useTranslation } from 'next-i18next';

const ChangeLanguage = () => {
  // const { i18n } = useTranslation();
  const { isMobile } = useConfigProvider();

  const router = useRouter();
  const currentLocale = router.locale;

  const currentColor = (color: string) => {
    return color === currentLocale ? `var(--app-primary-color)` : `var(--app-text-color)`;
  };

  const items = () => {
    return (
      <div className={styles.listContainer} style={{ color: `var(--app-text-color)` }}>
        {AllSupportLanguages.map((item) => {
          return (
            <div style={{ padding: 5 }} key={item.code}>
              <span
                style={{ color: currentColor(item.code), cursor: 'pointer' }}
                onClick={() => (window.location.href = `/${item?.code}${router.asPath}`)}
              >
                {item.name}
              </span>
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <div className={styles.mainContainer}>
      <Tooltip title={items} color={'#FFF'} placement="bottomRight">
        <div className={styles.content}>
          <img
            src={
              isDrFans || router.pathname.includes('top-instagram')
                ? '/images/layouts/language_icon.webp'
                : '/images/layouts/language_icon_dark.webp'
            }
            alt="language"
          />
          {!isMobile && (
            <span
              style={{
                color: router.pathname.includes('top-instagram')
                  ? `var(--other-text-color)`
                  : 'var(--app-text-color)',
              }}
            >
              {CurrentLanguage(currentLocale).name}
            </span>
          )}
        </div>
      </Tooltip>
    </div>
  );
};

export default ChangeLanguage;
