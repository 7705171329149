import type { ReactNode } from 'react';
import { NavHamburger } from '@/components/Layouts';
import styles from './index.module.less';
import { AppSettings } from '@/shared/app-common';
import ChangeLanguage from '@/components/Common/Language';
import Image from 'next/image';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';

const MobileHeader = ({ children }: { children?: ReactNode[] }) => {
  const { t } = useTranslation('common');
  const router = useRouter();
  return (
    <div className={styles.mobileHeaderWarp}>
      <div className={styles.navToolbar}>
        <div className={styles.navToolbarSection}>
          <NavHamburger>{children}</NavHamburger>
        </div>

        <div className={styles.navToolbarSection}>
          {router?.pathname?.includes('analytics') ? (
            <h2 style={{ fontWeight: '700', fontSize: '20px' }}>{t('Activity Report')}</h2>
          ) : (
            <div className={styles.logo} style={{ display: 'flex', alignItems: 'center' }} onClick={() => router.push(AppSettings.webHost)}>
              <Image
                className={styles.pietra}
                src={AppSettings.logoPath}
                alt={AppSettings.name}
                title={AppSettings.name}
                width={32}
                height={32}
              />
              <img
                src={AppSettings.namePath}
                alt={AppSettings.name}
                style={{ height: '32px', marginLeft: '10px' }}
                loading="lazy"
                width={154}
                height={32}
              />
            </div>
          )}
        </div>

        <div className={styles.navToolbarSection}>
          {/* <NavMobileSearch className={styles.searchIcon} /> */}
          <ChangeLanguage />
        </div>
      </div>
    </div>
  );
};
export default MobileHeader;
