import React, { useMemo } from 'react';
import { GlobalHeader, GlobalFooter } from '@/components/Layouts';
import { useConfigProvider } from '@/context/ConfigProvider';
import classNames from 'classnames';
import './index.less';
import { AppSettings, PageType } from '@/shared/app-common';
import { useTranslation } from 'next-i18next';
import { EventName } from '@/shared/event-types';
import { useRouter } from 'next/router';
import { Flex } from 'antd';
import Image from 'next/image';

interface LayoutProps {
  head?: React.ReactNode;
  children: React.ReactNode;
  showHeader?: boolean;
  showFooter?: boolean;
  pageType?: PageType;
}

interface TdkData {
  title?: string;
  description?: string;
  keywords?: string;
}

const Layout = ({
  children,
  head,
  showHeader = true,
  showFooter = true,
  pageType = PageType.Normal,
}: LayoutProps) => {
  const { isMobile, isBlogWeb } = useConfigProvider();
  // const { onAddToken, onOpenLoginModal, onRefreshLoginLoading, isLoginLoading, isLogin } = useUserProvider();

  const { t } = useTranslation('common');
  // startData和其他样式区分
  const router = useRouter();
  // 获取当前路径
  // const currentPath = router.asPath;
  // const currentLocale = router.locale;
  // // const { data: tdkData, isLoading } = useQuery<TdkData>({
  // //   queryKey: ['tdk', currentPath, currentLocale],
  // //   staleTime: 1000 * 60 * 5,
  // //   refetchOnMount: false,
  // // });
  const isInstagramTopAccounts = useMemo(() => {
    return router.pathname.includes('top-instagram');
  }, [router.pathname]);

  const getMarginTop = useMemo(() => {
    let topMarginTop = '0px';
    if (isInstagramTopAccounts) {
      topMarginTop = '0px';
    } else {
      topMarginTop = '62px';
    }
    if (!showHeader) {
      topMarginTop = '0px';
    }
    return topMarginTop;
  }, [isInstagramTopAccounts, showHeader, isMobile]);

  // app sub title
  const getNavItems = useMemo(() => {
    return [
      {
        text: t('Features'),
        gaName: EventName.header_Features,
        dropdownItems: [
          {
            text: (
              <Flex align="center">
                <Image
                  style={{ marginRight: '10px' }}
                  src="/images/home/TrackingTool/glasses-icon.webp"
                  alt="View Instagram Anonymously"
                  width={22}
                  height={22}
                  loading="lazy"
                />
                {t('View Instagram Anonymously')}
              </Flex>
            ),
            path: isBlogWeb
              ? `${AppSettings.webHost}/view-instagram-anonymously`
              : '/view-instagram-anonymously',
            gaName: EventName.header_Features_ViewInstagramAnonymously,
          },
          {
            text: (
              <Flex align="center">
                <Image
                  style={{ marginRight: '10px' }}
                  src="/images/home/TrackingTool/track-icon.webp"
                  alt="Track Instagram Anonymously"
                  width={22}
                  height={22}
                  loading="lazy"
                />
                {t('Track Instagram Anonymously')}
              </Flex>
            ),
            path: isBlogWeb ? `${AppSettings.webHost}/instagram-tracker` : '/instagram-tracker',
            gaName: EventName.header_Features_TrackInstagramAnonymously,
          },
          {
            text: (
              <Flex align="center">
                <Image
                  style={{ marginRight: '10px' }}
                  src="/images/home/TrackingTool/download-icon.webp"
                  alt="Download Instagram Stories"
                  width={22}
                  height={22}
                  loading="lazy"
                />
                {t('Download Instagram Stories')}
              </Flex>
            ),
            path: isBlogWeb
              ? `${AppSettings.webHost}/download-from-instagram`
              : '/download-from-instagram',
            gaName: EventName.header_Features_DownloadInstagramStories,
          },
          {
            text: (
              <Flex align="center">
                <Image
                  style={{ marginRight: '10px' }}
                  src="/images/home/TrackingTool/instagram_viewer_icon.webp"
                  alt="Download Instagram Stories"
                  width={22}
                  height={22}
                  loading="lazy"
                />
                {t('Instagram Viewer')}
              </Flex>
            ),
            path: isBlogWeb ? `${AppSettings.webHost}/instagram-viewer` : '/instagram-viewer',
            gaName: EventName.header_Features_InstagramViewer,
          },
          {
            text: (
              <Flex align="center">
                <Image
                  style={{ marginRight: '10px' }}
                  src="/images/home/TrackingTool/trends-icon.webp"
                  alt="Discover Instagram Influencer Trends"
                  width={22}
                  height={22}
                  loading="lazy"
                />
                {t('Discover Instagram Influencer Trends')}
              </Flex>
            ),
            path: isBlogWeb
              ? `${AppSettings.webHost}/instagram-influencer-trends`
              : '/instagram-influencer-trends',
            gaName: EventName.header_Features_DiscoverInstagramInfluencerTrends,
          },
          {
            text: (
              <Flex align="center">
                <Image
                  style={{ marginRight: '10px' }}
                  src="/images/home/TrackingTool/monitor-icon.webp"
                  alt="Monitor Suspicious Instagram Activity"
                  width={22}
                  height={22}
                  loading="lazy"
                />
                {t('Monitor Suspicious Instagram Activity')}
              </Flex>
            ),
            path: isBlogWeb
              ? `${AppSettings.webHost}/catch-instagram-cheaters`
              : '/catch-instagram-cheaters',
            gaName: EventName.header_Features_MonitorSuspiciousInstagramActivity,
          },
        ],
      },
      // {
      //   text: t('Analytics Sample'),
      //   path: isBlogWeb
      //     ? `${AppSettings.webHost}/instagram-tracking-example`
      //     : `/instagram-tracking-example`,
      //   gaName: EventName.header_Analytics_Sample,
      // },
      {
        text: t('Pricing'),
        path: isBlogWeb ? `${AppSettings.webHost}/plan` : `/plan`,
        gaName: EventName.header_Plan,
      },
      {
        text: t('blog'),
        path: isBlogWeb
          ? `/instagram-tracking-blog`
          : `${AppSettings.blogHost}/instagram-tracking-blog`,
        gaName: EventName.header_Blog,
      },
      {
        text: t('FAQ'),
        path: isBlogWeb
          ? `${AppSettings.webHost}/instagram-tracking-faq`
          : `/instagram-tracking-faq`,
        gaName: EventName.header_FAQ,
      },
      {
        text: t('Toolkit'),
        path: isBlogWeb ? `${AppSettings.webHost}/toolkit` : `/toolkit`,
        gaName: EventName.header_Toolkit,
        dropdownItems: [
          {
            text: (
              <Flex align="center">
                <Image
                  style={{ marginRight: '10px' }}
                  src="/images/toolkit/toolkit-icon1.png"
                  alt="Celebrity Influencers"
                  width={22}
                  height={22}
                  loading="lazy"
                />
                {t('Celebrity Influencers')}
              </Flex>
            ),
            path: isBlogWeb ? `${AppSettings.webHost}/top-instagram` : '/top-instagram',
            gaName: EventName.header_star_data,
          },
          {
            text: (
              <Flex align="center">
                <Image
                  style={{ marginRight: '10px' }}
                  src="/images/toolkit/toolkit-icon2.png"
                  alt="Celebrity Influencers"
                  width={22}
                  height={22}
                  loading="lazy"
                />
                {t('Anonymous Instagram Story Viewer')}
              </Flex>
            ),
            path: isBlogWeb
              ? `${AppSettings.webHost}/anonymous-instagram-story-viewer`
              : '/anonymous-instagram-story-viewer',
            gaName: EventName.header_view_instagram_anonymously,
          },
          {
            text: (
              <Flex align="center">
                <Image
                  style={{ marginRight: '10px' }}
                  src="/images/toolkit/toolkit-icon3.png"
                  alt="Post Viewer for Instagram"
                  width={22}
                  height={22}
                  loading="lazy"
                />
                {t('Post Viewer for Instagram')}
              </Flex>
            ),
            path: isBlogWeb
              ? `${AppSettings.webHost}/web-viewer-for-instagram`
              : '/web-viewer-for-instagram',
            gaName: EventName.header_web_viewer_for_instagram,
          },
          {
            text: (
              <Flex align="center">
                <Image
                  style={{ marginRight: '10px' }}
                  src="/images/toolkit/toolkit-icon4.webp"
                  alt="Hashtag Generator for Instagram"
                  width={22}
                  height={22}
                  loading="lazy"
                />
                {t('Hashtag Generator For Instagram')}
              </Flex>
            ),
            path: isBlogWeb
              ? `${AppSettings.webHost}/instagram-hashtag-generator`
              : '/instagram-hashtag-generator',
            gaName: EventName.header_web_viewer_for_instagram,
          },
          {
            text: (
              <Flex align="center">
                <Image
                  style={{ marginRight: '10px' }}
                  src="/images/toolkit/toolkit-icon5.webp"
                  alt="Instagram Recent Follower Tracker"
                  width={22}
                  height={22}
                  loading="lazy"
                />
                {t('Instagram Recent Follower Tracker')}
              </Flex>
            ),
            path: isBlogWeb
              ? `${AppSettings.webHost}/instagram-new-follow-tracker`
              : '/instagram-new-follow-tracker',
            gaName: EventName.header_web_viewer_for_instagram,
          },
          {
            text: (
              <Flex align="center">
                <Image
                  style={{ marginRight: '10px' }}
                  src="/images/toolkit/toolkit-icon6.webp"
                  alt="Instagram Unfollower Tracker"
                  width={22}
                  height={22}
                  loading="lazy"
                />
                {t('Instagram Unfollower Tracker')}
              </Flex>
            ),
            path: isBlogWeb
              ? `${AppSettings.webHost}/instagram-unfollowers-tracker`
              : '/instagram-unfollowers-tracker',
            gaName: EventName.header_web_viewer_for_instagram,
          },
        ],
      },
    ];
  }, [AppSettings.type, isBlogWeb]);

  const headerContent = useMemo(() => {
    return (
      <>
        {showHeader && (
          <header className={'header'}>
            <GlobalHeader
              isMobile={isMobile}
              navItems={getNavItems}
              isStarData={isInstagramTopAccounts}
            />
          </header>
        )}
      </>
    );
  }, [showHeader, isMobile, getNavItems, router.pathname]);

  const footerContent = useMemo(() => {
    return (
      <>
        {showFooter && (
          <footer className={'footer'}>
            <GlobalFooter pageType={pageType} />
          </footer>
        )}
      </>
    );
  }, [showFooter, pageType]);

  return (
    <div className={'layout'}>
      {head}
      {headerContent}
      <main
        // ref={ref}
        style={{ marginTop: getMarginTop }}
        className={classNames('main', {
          mobile: isMobile,
        })}
      >
        {children}
      </main>
      {footerContent}
    </div>
  );
};
export default Layout;
