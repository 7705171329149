import { ChevronUp, ChevronDown } from 'lucide-react'
import styles from './index.module.less';
import { useRouter } from 'next/router';
import classNames from 'classnames';
import { useLocation, useWindowSize } from 'react-use';
import React, { useState } from 'react';
import { useConfigProvider } from '@/context/ConfigProvider';
import { ga_logEvent } from '@/shared/ga';
import { Dropdown } from 'antd';
import { EventName } from '@/shared/event-types';

export interface MarketplaceNavItem {
  text: string | React.ReactNode;
  path?: string;
  gaName?: string;
  icon?: { left: string; right: string };
  dropdownItems?: MarketplaceNavItem[];
  onClick?: (item: MarketplaceNavItem) => void;
  onHover?: (isHover?: boolean) => void;
}

const NavItem = ({ item, className, onHover }: { item: MarketplaceNavItem; className?: string; onHover?: (isHover?: boolean) => void }) => {
  const { isMobile } = useConfigProvider();
  const router = useRouter();
  const location = useLocation();
  const [openItem, setOpenItem] = useState<MarketplaceNavItem | null>(null);
  const { width } = useWindowSize()

  const getColor = (pathStr: string) => {
    let end =
      pathStr != '/' &&
      (location?.pathname?.includes('/toolkit')
        ? pathStr?.includes('/toolkit')
        : location?.pathname?.toLowerCase().endsWith(pathStr?.toLowerCase()));
    let isStarData = router.pathname == '/starData' || router.pathname.startsWith('/top-instagram');
    return end
      ? 'var(--app-primary-color)'
      : isStarData && !isMobile
        ? '#ffffff'
        : `var(--app-text-color)`;
  };

  const getDropdownColor = (pathStr: string) => {
    return router.asPath === pathStr ? `var(--app-primary-color)` : `var(--app-text-color)`;
  };

  const handleItemClick = (e: any, item: MarketplaceNavItem) => {
    if (item?.path === router?.pathname) return;
    if (item?.gaName) {
      ga_logEvent(item?.gaName);
    }
    if (item?.onClick) {
      item.onClick(item);
      return;
    }

    if (item?.path) {
      if (item?.path?.includes('https://blog-test.dolphinradar.com') || item?.path?.includes('https://blog.dolphinradar.com')) {
        window.open(item?.path, '_blank');
      } else {
        router.push(item?.path || '/');
      }
    } else {
      if (item?.gaName === EventName.header_Features) {
        if (width > 900) {
          
        } else {
          e.stopPropagation();
          setOpenItem(item !== openItem ? item : null);
        }
      }
    }
  };

  const renderItem = (item: MarketplaceNavItem, index?: string | number) => {

    const getDropdownItems = (item: MarketplaceNavItem) => {

      if (isMobile && item.dropdownItems?.length > 0) {
        return <>
          <div className={styles.phoneNavItemWarp}>
            <span className={styles.navItemText} style={{ color: getColor(item?.path), width: '100%' }} onClick={(e) => handleItemClick(e, item)}>
              {item.text}
            </span>
            {item !== openItem ? <ChevronDown
              style={{ padding: '5px' }}
              onClick={(e) => {
                e.stopPropagation();
                setOpenItem(item !== openItem ? item : null);
              }} /> : <ChevronUp
              style={{ padding: '5px' }}
              onClick={(e) => {
                e.stopPropagation();
                setOpenItem(item !== openItem ? item : null);
              }} />}
          </div>

          {openItem?.dropdownItems?.map((item, index) => {
            return <div
              key={item?.gaName + item?.path + index}
              className={styles.navItemText}
              style={{ color: getColor(item?.path), padding: '5px 0', minHeight: '40px', display: 'flex', alignItems: 'center', width: '100%' }}
              onClick={(e) => handleItemClick(e, item)}
            >
              {item.text}
            </div>
          })}
        </>

      } else {
        return (
          (item.dropdownItems && item?.gaName !== EventName.header_Features) ? (
            <Dropdown
              menu={{
                items: item.dropdownItems.map((item, index) => {
                  return {
                    key: index,
                    label: (
                      <span
                        style={{ color: getDropdownColor(item?.path) }}
                        onClick={(e) => {
                          e.stopPropagation();
                          handleItemClick(e, item);
                        }}
                      >
                        {item.text}
                      </span>
                    ),
                  };
                }),
              }}
              placement="bottom"
              autoAdjustOverflow={false}
            >
              <div className={styles.navItemTextDiv} style={{ justifyContent: 'space-between', alignItems: 'center' }}>
                <span className={styles.navItemText} style={{ color: getColor(item?.path), flex: '1' }} onClick={(e) => handleItemClick(e, item)}>
                  {item.text}
                </span>
                <ChevronDown />
              </div>
            </Dropdown>
          ) : (<span
            className={classNames(styles.navItemText, isMobile && styles.phoneNavItemWarp)}
            style={{ color: getColor(item?.path), width: '100%', alignItems: 'center', display: 'flex', flex: '1' }}
            onClick={(e) => handleItemClick(e, item)}
          >
            <span style={{ flex: '1' }}>{item.text}</span>
            {(item?.gaName === EventName.header_Features) && <ChevronDown />}
          </span>
          )
        )
      }
    }

    return (
      <div
        className={classNames(!isMobile && styles.navItemWarp, className)}
        // className={classNames(className)}
        key={index}
        onMouseEnter={() => onHover && onHover(true)}
        onMouseLeave={() => onHover && onHover(false)}
      >
        <div
          // className={isMobile ? styles.navItemMobileText : ''}
          style={{
            color: getColor(item?.path),
            fontWeight: item?.path?.includes('top-instagram') ? 'bloder' : '',
            cursor: 'pointer'
          }}
        >
          {getDropdownItems(item)}
        </div>
      </div>
    );
  };

  return renderItem(item, `nav-item`);
};


export default NavItem;
