import { Divider, Drawer } from 'antd';
import { useToggle } from 'react-use';
import classNames from 'classnames';
import { useConfigProvider } from '@/context/ConfigProvider';
import styles from './index.module.less';
import { useCallback, useState, type ReactNode } from 'react';
import { AppSettings } from '@/shared/app-common';
import { CloseOutlined } from '@ant-design/icons';
import { useUserProvider } from '@/context/UserProvider';
import { useRouter } from 'next/router';
import Link from 'next/link';
import { useTranslation } from 'next-i18next';
import Image from 'next/image';
import CommonSimpleModal from '@/components/Common/CommonSimpleModal';

const NavHamburger = ({ children }: { children?: ReactNode[] }) => {
  const { isMobile } = useConfigProvider();
  const { isLogin, onOpenLoginModal, onChangedLoginView, onSignOut } = useUserProvider();
  const [open, toggle] = useToggle(false);
  const router = useRouter();
  const { t } = useTranslation('common');
  const [showGuideAlert, setShowGuideAlert] = useState(false);

  const showLogin = (loginView: boolean) => {
    onOpenLoginModal(true);
    onChangedLoginView(loginView);
  };

  const bottomItems = useCallback(() => {
    return (
      <div className={styles.mobileNavMenuWarp}>
        {isLogin ? (
          <>
            <span
              className={styles.linkTitle}
              onClick={() => router.push(`${AppSettings.webHost}/dashboard`)}
            >
              {t('Dashboard')}
            </span>
            <span
              className={styles.linkTitle}
              onClick={() => router.push(`${AppSettings.webHost}/settings`)}
            >
              {t('Settings')}
            </span>
            <span
              className={styles.linkTitle}
              onClick={() => {
                router.push('/');
                onSignOut(true);
              }}
            >
              {t('LogOut')}
            </span>
          </>
        ) : (
          <>
            <span className={styles.linkTitle} onClick={() => showLogin(true)}>
              {t('LogIn')}
            </span>
            <span className={styles.linkTitle} onClick={() => showLogin(false)}>
              {t('SignUp')}
            </span>
          </>
        )}
      </div>
    );
  }, [isLogin]);

  return (
    <div
      className={classNames(styles.navHamburgerWarp, {
        [styles.showClose]: open,
      })}
      onClick={toggle}
    >
      {new Array(3).fill(1).map((_, index) => (
        <span key={`burger-${index}`} className={styles.hamburgerLine} />
      ))}
      <Drawer
        style={{ background: `var(--foreground-rgb)` }}
        title={
          <Link href={AppSettings.webHost} style={{ display: 'flex', alignItems: 'center' }}>
            <Image
              src={AppSettings.logoPath}
              alt={AppSettings.name}
              style={{ height: '32px' }}
              loading="lazy"
              width={32}
              height={32}
            />
            <Image
              src={AppSettings.namePath}
              alt={AppSettings.name}
              style={{ height: '32px', marginLeft: '10px' }}
              loading="lazy"
              width={154}
              height={32}
            />
          </Link>
        }
        closeIcon={<CloseOutlined style={{ color: `var(--app-text-color)` }} />}
        closable
        width={isMobile ? '85%' : 400}
        placement="left"
        onClose={() => {
          toggle(true);
        }}
        open={open}
        rootClassName={styles.leftMenuDrawerWarp}
        key={'left-menu-drawer'}
      >
        {/* <div className={styles.mobileNavMenuWarp}>{children}</div> */}
        {isMobile && (
          <div
            className={classNames(styles.itemSection)}
            onClick={(e) => {
              router.push('/');
            }}
          >
            <span style={{ flex: '1' }}>{t('Home')}</span>
          </div>
        )}

        {children}
        <Divider style={{ margin: '10px 0' }} />
        {bottomItems()}

        <div className={styles.mediaSection}>
          <Link href="https://twitter.com/AnitaBestln" target="__block" className={styles.link}>
            <img src="/images/layouts/x-link-icon.webp" alt="x" />
          </Link>
          <Link
            href="https://www.youtube.com/@DolphinRadar"
            target="__block"
            className={styles.link}
          >
            <img src="/images/layouts/youtube.webp" alt="youtobe" />
          </Link>
        </div>

        <div className={styles.mediaSection}>
          <Link href={`${AppSettings.webHost}/privacy-policy`} className={styles.link}>
            {t('privacyPolicy')}
          </Link>
          <Link href={`${AppSettings.webHost}/terms-of-use`} className={styles.link}>
            {t('Terms of Use')}
          </Link>
        </div>
      </Drawer>

      {showGuideAlert && (
        <CommonSimpleModal
          open={showGuideAlert}
          title={
            <div style={{ width: '100%', textAlign: 'left' }}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Image src={'/images/layouts/download-icon.webp'} alt="" width={16} height={16} />
                <span style={{ marginLeft: '5px' }}>{t('Installation Guide')}</span>
              </div>

              <Divider />
            </div>
          }
          desc={
            <div style={{ width: '100%', textAlign: 'left' }}>
              <div>
                <p style={{ marginBottom: '5px' }}>{t('1. Press the share button')}</p>
                <img src="/images/pwa/pwa-download-1.webp" alt="" style={{ width: '80%' }} />
              </div>

              <div style={{ marginTop: '10px' }}>
                <p style={{ marginBottom: '5px' }}>{t('2. Select “Add to Home Screen”')}</p>
                <img src="/images/pwa/pwa-download-2.webp" alt="" style={{ width: '80%' }} />
              </div>

              <div style={{ marginTop: '10px' }}>
                <p style={{ marginBottom: '5px' }}>{t('3. Press the “Add” button')}</p>
                <img src="/images/pwa/pwa-download-3.webp" alt="" style={{ width: '80%' }} />
              </div>
            </div>
          }
          closeAction={(_, e) => {
            e?.stopPropagation();
            setShowGuideAlert(false);
          }}
        />
      )}
    </div>
  );
};

export default NavHamburger;
